<template lang="pug">
  .w-our-story.widget
    img.w-our-story__decor-top(src="@/assets/illustrations/flower.svg")
    img.w-our-story__decor-bottom(src="@/assets/illustrations/flower.svg")
    .w-our-story__content
      .script-title {{ $t('our_story.text_1') }}
      v-animate
        .w-our-story__item
          img.w-our-story__avatar(src="@/assets/img/profile-01.png")
          .title {{ $t('our_story.text_2') }}
          p.w-our-story__items-offset {{ $t('our_story.text_3') }}
      v-animate
        .w-our-story__item
          img.w-our-story__avatar(src="@/assets/img/profile-02.png")
          .title {{ $t('our_story.text_4') }}
          p {{ $t('our_story.text_5') }}
</template>

<script>
export default {
  name: 'OurStoryWidget'
}
</script>

<style lang="scss" scoped>
  .w-our-story {
    position: relative;
    overflow: hidden;

    .title {
      margin-bottom: 1.5rem;
    }

    .script-title {
      margin-bottom: 3rem;
    }

    &__decor-top {
      position: absolute;
      top: -25rem;
      left: -2rem;
      width: 105%;
      transform: rotate(170deg);
    }

    &__decor-bottom {
      position: absolute;
      bottom: -25rem;
      width: 105%;
      left: 0;;
      transform: rotate(-10deg);
    }

    &__content {
      position: relative;
      z-index: 1;
      padding: 10rem 2.4rem;
      padding-top: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: 1.4rem;
        text-transform: none;
        line-height: 1.8rem;
        max-width: 30rem;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__avatar {
      width: 15rem;
      margin-bottom: 2.8rem;
    }

    &__items-offset {
      margin-bottom: 7rem;
    }
  }
</style>
