<template lang="pug">
  .w-present
    img.w-present__icon(src="@/assets/svg/present.svg")
    p(v-html="$t('present.text_1')")
    v-dropdown(center)
      button.btn {{ $t('present.text_2') }}
      template(slot="content")
        .w-present__card
          .w-present__item {{ $t('present.text_2') }}
          .w-present__item {{ $t('present.text_3') }}
          .w-present__item {{ $t('present.text_4') }}
          .w-present__item {{ $t('present.text_5') }}
          .w-present__item {{ $t('present.text_6') }}
</template>

<script>
export default {
  name: 'PresentWidget'
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_variables.scss';

  .w-present {
    padding: 16rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__icon {
      width: 4rem;
      margin-bottom: 2.5rem;
    }

    &__card {
      background-color: $color-background;
      border-radius: 1rem;
      padding: 1.4rem;
      width: 29rem;
      box-shadow: 0 0 2.4rem rgba(12, 5, 2, .2);
    }

    &__item {
      border-radius: 1rem;
      text-align: center;
      font-size: 1.6rem;
      padding: 1.4rem;
      border: .1rem solid $color-secondary;
      user-select: text;
      -webkit-user-select: text;
      -khtml-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;

      &:not(:last-child) {
        margin-bottom: 1.4rem;
      }
    }

    p {
      margin-bottom: 3rem;
      text-transform: unset;
    }
  }
</style>
