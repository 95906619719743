<template lang="pug">
  .w-address.widget
    v-animate
      img.w-address__decor-right(
        src="@/assets/illustrations/flower.svg")
      img.w-address__decor-left(
        src="@/assets/illustrations/flower.svg")
    .w-address__content
      .title(v-html="$t('address.text_1')")
      p.w-address__text(v-html="$t('address.text_2')")
      a.w-address__link(
        target="_blank"
        href="https://goo.gl/maps/Ha7kqv46HcLz7sSz9")
        | {{ $t('address.text_3') }}
</template>

<script>
export default {
  name: 'AddressWidget'
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_variables.scss';

  .w-address {
    position: relative;

    .title {
      font-size: 2.5rem;
      line-height: 3rem;
    }

    &__text {
      margin-top: 3.5rem;
      margin-bottom: 3.5rem;
    }

    &__link {
      display: inline-block;
      color: $color-primary;
      background-color: rgba($color-secondary, .5);
      font-size: 1.4rem;
      padding: 1.4rem 3rem;
      border-radius: 1rem;
      text-decoration: none;
      text-transform: uppercase;

      &:visited {
        color: $color-primary;
      }

      &:not(:last-child) {
        margin-bottom: 8rem;
      }
    }

    &__decor-right {
      position: absolute;
      top: -26rem;
      right: -8rem;
      width: 100%;
      transform: rotate(-180deg);
      z-index: 0;
    }

    &__decor-left {
      position: absolute;
      left: -1rem;
      bottom: -28rem;
      width: 105%;
      transform: rotate(-9.52deg);
      z-index: 0;
    }

    &__content {
      padding: 24rem 4.5rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
  }
</style>
