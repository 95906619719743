<template lang="pug">
  .w-get-in-touch
    img.w-get-in-touch__decor(src="@/assets/illustrations/flower-branch.svg")
    p(v-html="$t('getintouch.text_1')")
    a.btn(href="tel:+1 209-345-0120")
      | {{ $t('getintouch.text_2') }}
</template>

<script>
export default {
  name: 'GetInTouch'
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_variables.scss';

  .w-get-in-touch {
    padding: 12rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $color-white;
    background-color: #CE9189;

    &__decor {
      width: 7.5rem;
      margin-bottom: 2.5rem;
    }

    p {
      line-height: 1.4;
      text-transform: none;
      margin-bottom: 2.5rem;
    }

    .btn {
      background-color: #C9867E;
    }
  }
</style>
