<template lang="pug">
  .v-counter
    button.v-counter__btn(@click="dec")
      img.v-counter__icon(src="@/assets/svg/minus.svg")
    .v-counter__value {{ count }}
    button.v-counter__btn(@click="inc")
      img.v-counter__icon(src="@/assets/svg/plus.svg")
</template>

<script>
export default {
  name: 'VCounter',

  data: () => ({
    count: 0
  }),

  methods: {
    dec () {
      if (this.count > 0) {
        this.count--
        this.$emit('change', this.count)
      }
    },

    inc () {
      if (this.count < 15) {
        this.count++
        this.$emit('change', this.count)
      }
    }
  }
}
</script>

<style lang="scss">
  .v-counter {
    color: #554A3D;
    padding: .8rem;
    border-radius: 1rem;
    border: .1rem solid #C9867E;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 19rem;

    &__btn {
      padding: 0;
      height: 2.4rem;
      width: 2.4rem;
      border-radius: 50%;
      background-color: #FBF9ED;
      display: flex;
      align-items: center;
      justify-content: center;

      &:active {
        background-color: #E8C3B9;
      }
    }

    &__value {
      font-size: 1.8rem;
    }

    &__icon {
      width: 1rem;
    }
  }
</style>
